import { default as _91form_93R5RH1t0lknMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as deniedlt5kkXSEReMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finishw9xEbS67GnMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as interactionXVKQTpQ540Meta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/interaction.vue?macro=true";
import { default as startkOZ9rOwdsxMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93PpDT0e20DvMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexRNsbcmbyotMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/index.vue?macro=true";
import { default as logingXvfcZlbQEMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectRwgwu1agpYMeta } from "/codebuild/output/src3001013643/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: "research",
    path: "/:research?",
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]].vue"),
    children: [
  {
    name: "research-form",
    path: ":form()",
    meta: _91form_93R5RH1t0lknMeta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/[form].vue")
  },
  {
    name: "research-denied",
    path: "denied",
    meta: deniedlt5kkXSEReMeta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/denied.vue")
  },
  {
    name: "research-finish",
    path: "finish",
    meta: finishw9xEbS67GnMeta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/finish.vue")
  },
  {
    name: "research-interaction",
    path: "interaction",
    meta: interactionXVKQTpQ540Meta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/interaction.vue")
  },
  {
    name: "research-start",
    path: "start",
    meta: startkOZ9rOwdsxMeta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/[[research]]/start.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexRNsbcmbyotMeta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logingXvfcZlbQEMeta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/login.vue")
  },
  {
    name: "redirect",
    path: "/redirect",
    meta: redirectRwgwu1agpYMeta || {},
    component: () => import("/codebuild/output/src3001013643/src/form.winx.ai/pages/redirect.vue")
  }
]