export default defineI18nLocale(async () => {
  return {
    base: {
      privacy: 'By using Winx solutions, you agree to our {privacyPolicy}.',
      privacyPolicy: 'Privacy Policy',
    },
    titleBase: 'Winx Survey',
    _pages: {
      _login: {
        _step1: {
          title: 'Survey',
          subtitle: 'To start, select the access method provided by your company.',
          _optionEmail: {
            title: 'E-mail access',
            description: 'Use your work email address.',
          },
          _optionPhone: {
            title: 'SMS access',
            description: 'Use your work phone number.',
          },
          _optionDoc: {
            title: 'Social security number access',
            description: 'Use your social security number.',
          },
        },
        _step2: {
          title: 'Access by @:{option}',
          subtitleOtp: 'Enter your work @:{option} to receive a one-time access code.',
          subtitle: 'Enter your document number to access.',
          placeholderEmail: 'work @:wb.email ',
          placeholderPhone: 'work @:wb.phone',
          placeholderDoc: '@:wb.document',
        },
        _step3: {
          title: 'Verification code',
          subtitle: 'We sent a 6-digit code to your work @:{option}.',
          resendCode: "Didn't receive the code?",
          resendByEmail: 'Send new code via e-mail',
          resendByPhone: 'Send new code via SMS',
        },
        changeOfAccess: 'Is this not the correct access method?',
        support: "If you can't find a message from Winnie with the code, check your spam folder.It might be there.",
        comments: 'If you need help, just contact us through our {helpCenter}.',
        footer: 'Winx - Expand awareness for authentic happiness.',
        title: 'Unified Access',
        resendingAccess: 'A new access code has been sent to your @:{channel}.',
      },
      _index: {
        _header: {
          title: 'Hello, {name}. Good to see you here!',
          description:
            'This {private} and {encrypted} environment is dedicated to selecting surveys you want to answer and viewing your participation history in {companyName} activities. All securely.',
          private: 'private',
          encrypted: 'encrypted',
        },
        _table: {
          _tabs: {
            research: 'Survey',
            archive: 'History',
          },
          _status: {
            start: 'Not started',
            continue: 'In progress',
            home: 'Completed',
            answered: 'Answered',
          },
          _action: {
            start: 'Start',
            continue: 'Continue',
          },
          _empty: {
            title: 'No surveys available',
            message: "While you don't have any surveys to {action}, how about a coffee ? ",
            answer: 'fill in',
            show: 'show in your history',
          },
          answered: 'Answered',
          finishesAt: 'Expires in',
          answeredAt: 'Filled out in',
          finishedAt: 'Completed in',
        },
        title: 'Home',
      },
      _research: {
        _start: {
          _message: {
            title: 'Message from {companyName}',
            _msg1: {
              title: 'Completion time',
              message:
                'Set aside quality time to complete the survey. The form has {count} questions and takes an average of {tta} to fill out.',
              answer: 'question | questions',
              minute: 'minute | minutes',
            },
            _msg2: {
              title: 'Security and privacy',
              anonymous:
                'Rest assured! All the data you provide in this form is <strong>confidential and secure</strong>.\n' +
                'Your answers are anonymous, and the results only appear in groups of more than four people.\n' +
                'This means that the {companyName} will not see your responses individually nor know that they were yours. Everything is well protected!',
              identified:
                'Rest assured! All the data you provide in this form is <strong>secure</strong>.\n' +
                'Your answers are stored on our servers and the results will only be accessible to authorized individuals from {companyName}. Everything is well protected!',
            },
            _msg3: {
              title: 'Data processing',
              message: 'By starting the survey, you agree that Winx will store and process your responses anonymously.',
            },
          },
          _action: {
            deny: 'Not now',
            accept: 'Start the survey',
          },
          welcome:
            'There is a brief message from {companyName} about the purpose of this action, along with information from Winx on your privacy and security when completing the form.',
          title: 'Start survey',
        },
        _finish: {
          _message: {
            title: 'Help Winx become even better',
            message:
              'At Winx, we are obsessed with our users and clients. We’d love to hear about your experience using our platform.',
            _msg1: {
              title: 'Spread the news',
              message:
                "Invite your coworkers to participate in the surveys. The more people join {companyName}'s initiatives, the more effective the improvements to your workplace will be.",
            },
            _msg2: {
              title: 'Come back anytime',
              message:
                'This environment is yours to continuously contribute to surveys. Stay tuned for future communications and keep responding to our forms and participating in the initiatives.',
            },
          },
          _action: {
            home: 'Go to home page',
            rating: 'Review the experience',
            back: 'back',
            send: 'Send review',
          },
          _form: {
            title: 'I want to know!',
            answer: 'Leave your honest feedback about how it was to use our platform.',
            label: 'Write comment (optional)',
            dislike: "I didn't like it ",
            like: 'I love Winx',
            done: 'Review completed!',
            message: 'Thank you for evaluating Winx. Your feedback is crucial for improving our solutions.',
          },
          _footer: {
            title: 'You still have surveys to complete.',
          },
          welcome: 'Your responses will lead to concrete actions to improve your work environment.',
          title: 'I am very happy with your contribution.',
        },
        _interaction: {
          _message: {
            title: 'Receive My Personalized Recommendations',
            p1: 'Thank you for completing the form! Based on your responses, I have several recommendations that will assist in your professional self-development.',
            p2: 'If you would like to receive my messages, please enter your best email address in the field below. I promise that your email will be used only for this purpose and will not be shared with anyone. I, Winnie, do not send SPAM or advertisements.',
            label: 'Your best email address',
            _msg1: {
              title: 'Spread the news',
              message:
                "Invite your coworkers to participate in the surveys. The more people join {companyName}'s initiatives, the more effective the improvements to your workplace will be.",
            },
            _msg2: {
              title: 'Come back anytime',
              message:
                'This environment is yours to continuously contribute to surveys. Stay tuned for future communications and keep responding to our forms and participating in the initiatives.',
            },
          },
          _action: {
            back: 'Not now',
            send: 'I want to receive',
          },
          welcome: 'Your responses will lead to concrete actions to improve your work environment.',
          title: 'I am very happy with your contribution.',
        },
        _denied: {
          _message: {
            _msg1: {
              title: 'Give voice to your opinion.',
              message:
                'This survey is an excellent opportunity for you to anonymously share your experiences, opinions, and suggestions about your work.',
            },
            _msg2: {
              title: 'Deadline for completion',
              message: 'Find a calm moment in your work routine. The deadline to complete the survey is {finishesAt}.',
            },
            _msg3: {
              title: 'Security and privacy',
              anonymous:
                'Rest assured! All the data you provide in this form is <strong>confidential and secure</strong>.\n' +
                'Your answers are anonymous, and the results only appear in groups of more than four people.\n' +
                'This means that the {companyName} will not see your responses individually nor know that they were yours. Everything is well protected!',
              identified:
                'Rest assured! All the data you provide in this form is <strong>secure</strong>.\n' +
                'Your answers are stored on our servers and the results will only be accessible to authorized individuals from {companyName}. Everything is well protected!',
            },
          },
          _action: {
            home: 'Back to home',
          },
          welcome:
            'I understand this might not be the ideal time for you to participate. Feel free to come back and complete the survey later.',
          title: 'Survey not answered',
        },
        _form: {
          _instruction: {
            likert:
              'Considering {disagree} as the worst scenario and {agree} as the best, choose from the options below the one that best represents your opinion about the statement made.',
            nps: 'Considering {zero} as the worst scenario and {ten} as the best, choose from the options below the one that best represents your opinion about the given question.',
            radio:
              'Based on your work experience, choose the option below that best represents your response to the given statement or question.',
            checkbox:
              'Based on your work experience, choose the options below that best represent your response to the given statement or question.',
            textarea:
              'This is an open-ended question. Use the field below to freely write about the question. This environment is anonymous and you will not be identified.',
            select:
              'Based on your work experience, start typing in the search field below the name of the person who best represents the answer to the given question. Add one name at a time.',
            demographic:
              'Select the option that best applies to you. This is a confidential and anonymous response, and your participation is essential to ensure the representativeness of the data.',
          },
          _likert: {
            agree: 'Totally agree',
            partialAgree: 'Partially agree',
            neutral: 'Neutral',
            partialDisagree: 'Partially disagree',
            disagree: 'Totally disagree',
            na: 'Not applicable',
          },
          _nps: {
            zero: '0 (zero)',
            ten: '10 (ten)',
          },
          _comment: {
            label: 'If you wish, leave a comment to help us understand your choice:',
            placeholder: 'Make your comment (optional)',
          },
          _select: {
            label: 'Use the field above to search',
            disable: 'Limit of nominations reached',
          },
          _textarea: {
            label: 'Type your answer here',
          },
          _header: {
            title: 'Question {number} out of {total}',
          },
          _footer: {
            _action: {
              prev: 'Previous question',
              next: 'Next question',
              skip: 'Prefer not to answer',
              answer: 'Answer',
              back: 'Back',
              finish: 'Complete',
            },
            _confidential: {
              anonymous: 'Your answers are <strong>confidential and anonymous</strong>.',
              identified: 'Your answers are <strong>secure and access to them is restricted</strong>.',
            },
          },
          _modal: {
            title: "We've reached the end",
            anonymous:
              'This is the last question in this form. By clicking on {finish}, we will conclude your participation in this survey, and your answers will be <strong>stored in an anonymized form</strong> by Winx.',
            identified:
              'This is the last question in this form. By clicking on {finish}, we will conclude your participation in this survey, and your responses will be stored <strong>securely</strong> by Winx.',
          },
          title: 'Complete survey',
        },
      },
    },
  };
});
